export default {
  BUTTON_PRIMARY_CLASS: 'btn',
  BUTTON_SECONDARY_CLASS: 'btn btn--secondary',
  SEARCH_BOX_BUTTON_CLASS: 'btn',

  CLEAR_TEXT: 'Clear',

  FACET_TITLE_ICON: `
  <rt-virtual rt-if="this.isCollapsed" template="icons/angle-down" />
  <rt-virtual rt-if="!this.isCollapsed" template="icons/angle-up" />`,
};
